@use "../globals.module.scss" as globals;

// Desktop //

.skillsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  min-height: 750px;
  height: fit-content;
  padding-bottom: 50px;
  color: globals.$color-1;
  background: rgb(242, 121, 92);
  background: linear-gradient(
    90deg,
    rgb(208, 87, 56) 0%,
    rgba(242, 156, 107, 1) 100%
  );
  overflow-x: hidden; // Fix du bug de la navbar fixed qui disparaît au moment des animations appliquées à la section
  .skillsTitle {
    @include globals.sectionTitleDesktop;
    padding: 70px 0 30px 0;
  }
  .skillsAnimate {
    -webkit-animation: tracking-in-expand 3s cubic-bezier(0.215, 0.61, 0.355, 1)
      both;
    animation: tracking-in-expand 4s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }
  .logosContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: fit-content;
    margin: 0 auto;
    padding-bottom: 30px;
    .logosFrontend,
    .logosBackend {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .logosFrontendAnimate {
      -webkit-animation: slide-in-left 2s ease 0s 1 normal forwards;
      animation: slide-in-left 2s ease 0s 1 normal forwards;
    }
    .logosBackendAnimate {
      -webkit-animation: slide-in-right 2s ease 0s 1 normal forwards;
      animation: slide-in-right 2s ease 0s 1 normal forwards;
    }
  }
}

// Desktop, Tablets //

@media screen and (max-width: 1000px) {
  .skillsContainer {
    height: auto;
    .logosFrontend,
    .logosBackend {
      justify-content: center;
    }
  }
}

// Mobiles //

@media screen and (max-width: 480px) {
  .skillsContainer {
    height: auto;
    .skillsTitle {
      @include globals.sectionTitleMobile;
    }
  }
}
