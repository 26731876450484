@use "../globals.module.scss" as globals;

// Desktop //

.logoContainer {
  position: relative;
  .imgContainer {
    @include globals.flexCenterXY;
    width: 120px;
    height: 120px;
    margin: 30px 30px;
    padding: 30px;
    background-color: #ffebd2;
    border-radius: 50%;
    box-shadow: rgba(39, 50, 72, 0.3) 20px 40px 30px -5px;
    .img {
      max-width: 50px;
    }
  }
  .technoNameContainer {
    @include globals.flexCenterXY;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    padding: 0 10px;
    font-size: globals.$font-size-xs;
    color: globals.$color-1;
    background-color: globals.$color-5;
    border-radius: 50%;
  }
}

// Tablets, mobiles //

@media screen and (max-width: 500px) {
  .logoContainer {
    .imgContainer {
      width: 95px;
      height: 95px;
      margin: 20px 20px;
    }
    .technoNameContainer {
      width: 95px;
      height: 95px;
      font-size: globals.$font-size-xxs;
    }
  }
}

// Mobiles //

@media screen and (max-width: 319px) {
  .logoContainer {
    .imgContainer {
      .img {
        height: 50px;
      }
    }
  }
}
