@use "../globals.module.scss" as globals;

// Desktop //

.workContainer {
  height: fit-content;
  padding: 50px 0;
  color: globals.$color-1;
  overflow-x: hidden; // Fix du bug de la navbar fixed qui disparaît au moment des animations appliquées à la section

  .workTitle {
    @include globals.sectionTitleDesktop;
    padding: 60px 0 70px 0;
  }
  .workAnimate {
    -webkit-animation: tracking-in-expand 3s cubic-bezier(0.215, 0.61, 0.355, 1)
      both;
    animation: tracking-in-expand 4s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }
}

// Mobile //

@media screen and (max-width: 480px) {
  .workContainer {
    .workTitle {
      @include globals.sectionTitleMobile;
    }
  }
}
