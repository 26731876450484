@use "../globals.module.scss" as globals;

// MIXINS //

@mixin imgConfiguration {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// Desktop //

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: fit-content;
  cursor: pointer;
  .card {
    position: relative;
    color: globals.$color-5;
    background: rgb(34, 52, 90);
  background: linear-gradient(
    90deg,
    rgb(28, 43, 76) 0%,
    rgb(59, 88, 130) 50%,
    rgb(105, 147, 205) 100%
  );
    width: 41%;
    max-width: 500px;
    height: 270px;
    margin: 30px;
    box-shadow: rgba(39, 50, 72, 0.3) 20px 40px 30px -5px;
    border-radius: 15px;
    .reason {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: globals.$font-family-2;
      text-align: center;
      .reasonNumber {
        font-size: globals.$font-size-4;
        margin-bottom: 20px;
      }
      .reasonTitle {
        font-size: globals.$font-size-3;
        width: 100%;
      }
    }
    .infos {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 270px;
      padding: 25px;
      z-index: 1;
      opacity: 0;
      border-radius: 15px;
      .title {
        font-size: globals.$font-size-1;
      }
      .date {
        margin: 10px 0;
        font-size: globals.$font-size-xs;
      }
      .details {
        @include globals.textAlignment;
        font-size: globals.$font-size-xs;
      }
      // Affichage des informations sur chaque projet au survol
      &:hover {
        background-color: globals.$color-1;
        color: globals.$color-5;
        opacity: 0.99;
        transition: 0.5s;
      }
    }
  }
}

// Small screens, laptops & Desktop, large screens //

@media screen and (max-width: 1200px) {
  .cardContainer {
    flex-direction: column;
    align-items: center;
    .card {
      position: relative;
      width: 70%;
      height: 300px;
      margin: 40px;
      box-shadow: rgba(39, 50, 72, 0.3) 0px 50px 30px -5px;
      .infos {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        height: 300px;
        z-index: 1;
        opacity: 0;
        .date {
          margin: 0;
        }
      }
    }
  }
}

// iPads, Tablets //

@media screen and (min-width: 600px) and (max-width: 768px) {
  .cardContainer {
    .card {
      width: auto;
      .infos {
        padding: 20px;
      }
    }
  }
}

// Tablets, Mobile devices //

// @media screen and (min-width: 320px) and (max-width: 599px) {
//   .cardContainer {
//     .card {
//       height: 380px;
//       margin: 50px 0;
//       border-radius: 15px;
//       .infos {
//         height: 100%;
//         padding: 30px;
//         border-radius: 15px;
//         .details {
//           margin-bottom: 10px;
//           font-size: globals.$font-size-xs;
//         }
//       }
//     }
//   }
// }

@media screen and (min-width: 470px) and (max-width: 599px) {
  .cardContainer {
    .card {
      height: 380px;
      margin: 50px 0;
      border-radius: 15px;
      .infos {
        height: 100%;
        padding: 30px;
        border-radius: 15px;
        .details {
          margin-bottom: 10px;
          font-size: globals.$font-size-xs;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 469px) {
  .cardContainer {
    .card {
      height: 320px;
      margin: 50px 0;
      border-radius: 15px;
      .infos {
        height: 100%;
        padding: 30px;
        border-radius: 15px;
        .details {
          margin-bottom: 10px;
          font-size: globals.$font-size-xxs;
        }
      }
    }
  }
}

@media screen and (max-width: 376px) {
  .cardContainer {
    .card {
      height: 320px;
      margin: 50px 0;
      .infos {
        height: 100%;
        padding: 15px;
        .details {
          font-size: 10px;
        }
      }
    }
  }
}
