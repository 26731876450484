@use "../globals.module.scss" as globals;

// Desktop //

.headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 16vh;
  font-size: globals.$font-size-1;
  letter-spacing: 2px;
  color: globals.$color-1;
  background-color: globals.$color-3;
  box-shadow: rgba(39, 50, 72, 0.3) 0px 50px 30px -5px;
  z-index: 2;
  .navbarContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    .navbarItem {
      display: flex;
      .navbarIcon {
        display: none;
      }
    }
    .active {
      color: globals.$color-5;
      transition: 0.4s;
    }
  }
}

// Tablets, Mobiles //

@media screen and (max-width: 890px) {
  .headerContainer {
    height: 80px;
    letter-spacing: normal;
    font-size: globals.$font-size-1;
  }
}

@media screen and (max-width: 600px) {
  .headerContainer {
    font-size: globals.$font-size-xs;
  }
}

// Mobiles //

@media screen and (max-width: 480px) {
  .headerContainer {
    font-size: globals.$font-size-1;
    .navbarContainer {
      .navbarItem {
        .navbarIcon {
          display: inline;
          font-size: globals.$font-size-3;
        }
        .navbarText {
          display: none;
        }
      }
    }
  }
}