@use "../globals.module.scss" as globals;

// MIXIN //

@mixin homeText {
  @include globals.textAlignment;
  width: 300px;
  max-width: 350px;
  font-family: globals.$font-family-1;
  font-size: globals.$font-size-1;
  font-weight: 400;
  color: globals.$color-3;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: globals.$font-size-xs;
  }
}

// Desktop //

.homeContainer {
  position: relative;
  @include globals.flexCenterXY;
  flex-direction: column;
  height: 900px;
  padding: 120px 0 70px 0;
  background: rgb(242, 121, 92);
  background: linear-gradient(
    90deg,
    rgb(240, 98, 63) 0%,
    rgba(34, 52, 90, 1) 100%
  );
  .homeTitle {
    @include globals.sectionTitleDesktop;
    margin: 20px 0 90px 0;
    color: globals.$color-3;
  }
  .titleAnimate {
    -webkit-animation: tracking-in-expand 3s cubic-bezier(0.215, 0.61, 0.355, 1)
      both;
    animation: tracking-in-expand 4s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }
  .homeWrapper {
    display: flex;
    justify-content: space-around;
    width: 80%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 35px 0;
    .textContainerLeft {
      @include homeText;
      animation: 4s ease-out forwards homeDoorTextLeft;
    }
    .img {
      height: 250px;
      margin: 25px 20px 0 20px;
      box-shadow: rgba(0, 0, 0, 0.3) 20px 40px 30px -5px;
      filter: blur(6px);
      border-radius: 8px;
    }
    .animateImg {
      animation: 3s ease-out forwards homeDoorImg;
    }
    .textContainerRight {
      @include homeText;
      animation: 4s ease-out forwards homeDoorTextRight;
    }
  }
}

// Desktop, Large screens //

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .homeContainer {
    .homeWrapper {
      width: 90%;
    }
  }
}

// Small screens, Laptops //

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .homeContainer {
    .homeWrapper {
      width: 90%;
      .img {
        margin: 25px 25px 0 25px;
      }
    }
  }
}

// Tablets, Mobiles //

@media screen and (min-width: 369px) and (max-width: 890px) {
  .homeContainer {
    width: 100%;
    height: fit-content;
    padding-bottom: 50px;
    .homeWrapper {
      flex-direction: column;
      align-items: center;
      width: fit-content;
      margin: 0 auto;
      .textContainerLeft {
        animation: none;
      }
      .img {
        margin: 50px 0;
      }
      .textContainerRight {
        animation: none;
      }
    }
  }
}

// Mobiles //

@media screen and (max-width: 480px) {
  .homeContainer {
    height: auto;
    .homeTitle {
      @include globals.sectionTitleMobile;
      margin: 20px 0;
    }
    .homeWrapper {
      flex-direction: column;
      align-items: center;
      width: fit-content;
      .textContainerLeft {
        font-size: globals.$font-size-1;
        animation: none;
      }
      .img {
        height: 200px;
        margin: 50px 0;
      }
      .textContainerRight {
        font-size: globals.$font-size-1;
        animation: none;
      }
    }
  }
}

@media screen and (max-width: 330px) {
  .homeContainer {
    .homeWrapper {
      .textContainerLeft,
      .textContainerRight {
        width: 250px;
      }
    }
  }
}
