@use "../globals.module.scss" as globals;

// Desktop //

.footerContainer {
    @include globals.flexCenterXY;
    flex-direction: column;
    height: 120px;
    font-size: globals.$font-size-xs;
    background-color: globals.$color-3;   
    .footerText {
        text-align: center;
        .footerLink {
            cursor: pointer;
        }
    }
}

// Mobile //

@media screen and (max-width: 480px) {
    .footerContainer {
        font-size: globals.$font-size-xxs;
    }
}