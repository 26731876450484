@use "../globals.module.scss" as globals;

// Desktop //

.contactContainer {
  @include globals.flexCenterXY;
  flex-direction: column;
  height: 800px;
  background-color: globals.$color-5;
  color: globals.$color-1;
  overflow-x: hidden; // Fix du bug de la navbar fixed qui disparaît au moment des animations appliquées à la section
  .contactTitle {
    @include globals.sectionTitleDesktop;
    padding-bottom: 70px;
  }
  .contactAnimate {
    -webkit-animation: tracking-in-expand 3s cubic-bezier(0.215, 0.61, 0.355, 1)
      both;
    animation: tracking-in-expand 4s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }
  .contactIntro {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    font-size: globals.$font-size-1;
    p:nth-child(1) {
      margin-bottom: 30px;
    }
  }
  .socialMediaContainer {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    .linkedinLogoAnimate {
      animation: slide-in-left 3s ease 0s 1 normal forwards;
    }
    .maltLogoAnimate {
      animation: apparition 1s ease normal forwards;
    }
    .githubLogoAnimate {
      animation: slide-in-right 3s ease 0s 1 normal forwards;
    }
  }
  .curriculumContainer {
    text-align: center;
    font-size: globals.$font-size-4;
    opacity: 0;
    animation: apparition 1.5s ease 0.5s 1 normal forwards;
    .curriculumLink {
      color: globals.$color-1;
    }
  }
  .emailAndSecretContainer {
    .emailContainer {
      display: flex;
      justify-content: space-around;
      max-width: 500px;
      padding: 20px 30px;
      margin-top: 50px;
      font-size: globals.$font-size-1;
      border: 2px solid;
      border-radius: 8px;
      .emailText {
        color: globals.$color-1;
      }
      .emailIcon {
        margin: 5px 25px 0 0;
      }
    }
    .secretContainer {
      display: flex;
      justify-content: center;
      font-size: globals.$font-size-1;
      .secretText {
        margin-top: 40px;
        animation: scrollingDown 2s ease-in-out;
        color: globals.$color-1;
      }
    }
  }
}

// Tablets //

@media screen and (min-width: 481px) and (max-width: 768px) {
  .contactContainer {
    .contactIntro {
      width: 80%;
      margin: 0 auto;
      font-size: globals.$font-size-xs;
      p:nth-child(1) {
        margin-bottom: 30px;
      }
    }
    .curriculumContainer {
      font-size: globals.$font-size-2;
    }
    .emailAndSecretContainer {
      width: 350px;
      .emailContainer {
        font-size: globals.$font-size-xs;
      }
      .secretContainer {
        .secretText {
          font-size: globals.$font-size-xs;
        }
      }
    }
  }
}

// Mobiles //

@media screen and (max-width: 480px) {
  .contactContainer {
    padding: 60px 0;
    .contactTitle {
      @include globals.sectionTitleMobile;
    }
    .contactIntro {
      font-size: globals.$font-size-xs;
    }
    .socialMediaContainer {
      margin-bottom: 30px;
    }
    .curriculumContainer {
      font-size: globals.$font-size-2;
    }
    .emailAndSecretContainer {
      width: 270px;
      .emailContainer {
        font-size: globals.$font-size-xxs;
        border: 1px solid;
        .emailIcon {
          margin: 5px 10px 0 0;
        }
      }
      .secretContainer {
        .secretText {
          font-size: globals.$font-size-xs;
        }
      }
    }
  }
}
