@use "../globals.module.scss" as globals;

// Desktop //

.trainingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 2550px;
  padding: 100px 0 300px 0;
  color: globals.$color-5;
  background: rgb(34, 52, 90);
  background: linear-gradient(
    90deg,
    rgb(28, 43, 76) 0%,
    rgb(59, 88, 130) 50%,
    rgb(105, 147, 205) 100%
  );
  overflow-x: hidden; // Fix du bug de la navbar fixed qui disparaît au moment des animations appliquées à la section
  .trainingTitle {
    @include globals.sectionTitleDesktop;
    margin-bottom: 50px;
  }
  .trainingAnimate {
    -webkit-animation: tracking-in-expand 3s cubic-bezier(0.215, 0.61, 0.355, 1)
      both;
    animation: tracking-in-expand 3s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }
  .boxTimeline {
    position: relative;
    display: flex;
    width: 100%;
    height: 2000px;
    padding: 80px 0 250px 0;
    .line {
      width: 7px;
      height: 1860px;
      margin: 0 auto;
      background: globals.$color-5;
    }
    .rond {
      @include globals.flexCenterXY;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: globals.$color-5;
      border: 1px solid globals.$color-5;
      .timelineIcon {
        font-size: globals.$font-size-3;
        color: globals.$color-2;
      }
    }
    .r1 {
      top: 80px;
    }
    .r2 {
      top: 450px;
    }
    .r3 {
      top: 760px;
    }
    .r4 {
      top: 1110px;
    }
    .r5 {
      top: 1356px;
    }
    .r6 {
      top: 1630px;
    }
    .r7 {
      top: 1890px;
    }
    .box {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      flex-direction: column;
      width: 400px;
      height: auto;
      min-height: 150px;
      padding: 20px;
      color: globals.$color-2;
      background-color: globals.$color-5;
      box-shadow: rgba(39, 50, 72, 0.3) 0px 50px 30px -5px;
      border-radius: 15px;
      opacity: 0;
      h2 {
        font-size: globals.$font-size-1;
      }
    }
    .boxAnimate {
      -webkit-animation: slide-top 1.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        forwards;
      animation: slide-top 1.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
    }
    .b1 {
      top: 80px;
      left: calc(50% - 270px);
    }
    .b2 {
      top: 450px;
      left: calc(50% + 270px);
    }
    .b3 {
      top: 760px;
      left: calc(50% - 270px);
    }
    .b4 {
      top: 1110px;
      left: calc(50% + 270px);
    }
    .b5 {
      top: 1356px;
      left: calc(50% - 270px);
    }
    .b6 {
      top: 1630px;
      left: calc(50% + 270px);
    }
    .b7 {
      top: 1890px;
      left: calc(50% - 270px);
    }
    .box p {
      @include globals.textAlignment;
      margin: 10px 0 10px 0;
      font-size: globals.$font-size-1;
    }
    .box p ul li {
      margin-left: 15px;
      list-style-type: circle;
    }
  }
}

// Desktop (small screens)

@media screen and (max-width: 1000px) {
  .trainingContainer {
    height: 2350px;
    .boxTimeline {
      height: 1900px;
      .line {
        position: relative;
        left: 200px;
      }
      .rond {
        left: calc(50% + 200px);
      }
      .box {
        left: calc(50% - 50px);
      }
    }
  }
}

@media screen and (min-width: 550px) and (max-width: 769px) {
  .trainingContainer {
    .boxTimeline {
      .box p {
        font-size: globals.$font-size-xs;
      }
    }
  }
}

// Tablets

@media screen and (max-width: 550px) {
  .trainingContainer {
    height: 2990px;
    .boxTimeline {
      height: 2500px;
      .line {
        position: relative;
        left: 200px;
        height: 2350px;
      }
      .rond {
        left: calc(50% + 200px);
      }
      .r1 {
        top: 80px;
      }
      .r2 {
        top: 550px;
      }
      .r3 {
        top: 940px;
      }
      .r4 {
        top: 1390px;
      }
      .r5 {
        top: 1710px;
      }
      .r6 {
        top: 2090px;
      }
      .r7 {
        top: 2390px;
      }
      .box {
        left: calc(50% + 30px);
        width: 300px;
      }
      .b1 {
        top: 80px;
      }
      .b2 {
        top: 550px;
      }
      .b3 {
        top: 940px;
      }
      .b4 {
        top: 1390px;
      }
      .b5 {
        top: 1710px;
      }
      .b6 {
        top: 2090px;
      }
      .b7 {
        top: 2390px;
      }
      .box h2 {
        font-size: globals.$font-size-xs;
      }
      .box p {
        font-size: globals.$font-size-xs;
      }
    }
  }
}

// Mobiles

@media screen and (max-width: 480px) {
  .trainingContainer {
    height: 2500px;
    margin: 0 auto;
    .trainingTitle {
      @include globals.sectionTitleMobile;
    }
    .boxTimeline {
      height: 2300px;
      .line {
        display: none;
      }
      .rond {
        left: calc(50% + 150px);
        border: 1px solid globals.$color-2;
        z-index: 1;
      }
      .r1 {
        top: 65px;
      }
      .r2 {
        top: 435px;
      }
      .r3 {
        top: 740px;
      }
      .r4 {
        top: 1120px;
      }
      .r5 {
        top: 1380px;
      }
      .r6 {
        top: 1700px;
      }
      .r7 {
        top: 1950px;
      }
      .box {
        left: calc(50% + 50px);
        p {
          font-size: globals.$font-size-xs;
        }
      }
      .b1 {
        top: 90px;
      }
      .b2 {
        top: 465px;
      }
      .b3 {
        top: 765px;
      }
      .b4 {
        top: 1145px;
      }
      .b5 {
        top: 1405px;
      }
      .b6 {
        top: 1725px;
      }
      .b7 {
        top: 1975px;
      }
    }
  }
}

@media screen and (min-width: 280px) and (max-width: 380px) {
  .trainingContainer {
    height: 2200px;
    margin: 0 auto;
    .trainingTitle {
      @include globals.sectionTitleMobile;
    }
    .boxTimeline {
      height: 2000px;
      .line {
        display: none;
      }
      .box {
        width: 240px;
        height: auto;
        margin-left: 20px;
        & h2 {
          font-size: globals.$font-size-1;
        }
        & p {
          font-size: globals.$font-size-xs;
        }
      }

      .rond {
        left: calc(50% + 110px);
        border: 2px solid globals.$color-2;
        z-index: 1;
      }
      .r1 {
        top: 60px;
      }
      .r2 {
        top: 400px;
      }
      .r3 {
        top: 800px;
      }
      .r4 {
        top: 1080px;
      }
      .r5 {
        top: 1440px;
      }
      .r6 {
        top: 1730px;
      }
      .box {
        left: calc(50% + 50px);
      }
      .b1 {
        top: 80px;
      }
      .b2 {
        top: 420px;
      }
      .b3 {
        top: 820px;
      }
      .b4 {
        top: 1100px;
      }
      .b5 {
        top: 1460px;
      }
      .b6 {
        top: 1750px;
      }
    }
  }
}
