@use "../globals.module.scss" as globals;

// Desktop //

.popupContainer {
  @include globals.textAlignment;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 280px;
  padding: 25px;
  color: globals.$color-1;
  background-color: globals.$color-5;
  border: 2px solid globals.$color-1;
  border-radius: 15px;
  opacity: 0.9;
  overflow-y: scroll;
  .popupTitle {
    text-align: center;
    margin-bottom: 30px;
    font-size: globals.$font-size-3;
  }
  .popupText {
    position: relative;
    width: 100%;
    margin-bottom: 80px;
    font-size: globals.$font-size-1;
  }
  .closeButton {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 120px;
    padding: 10px 20px;
    font-family: globals.$font-family-1;
    font-size: globals.$font-size-1;
    font-weight: 700;
    background-color: globals.$color-5;
    color: globals.$color-1;
    border: 2px solid globals.$color-1;
    border-radius: 8px;
    cursor: pointer;
  }
}

// Mobiles

@media screen and (max-width: 480px) {
  .popupContainer {
    .popupTitle {
      font-size: globals.$font-size-2;
    }
    .popupText {
      margin-bottom: 60px;
      font-size: globals.$font-size-xs;
    }
    .closeButton {
      font-size: globals.$font-size-xs;
    }
  }
}
